import pThumbnailIsraeliHelicopter from "./assets/video-thumbnails/PThumbnailIsraeliHelicopter.png";
import pThumbnailBadHousemaid from "./assets/video-thumbnails/PThumbnailBadHousemaid.png";
import pTHumbnailHardcore from "./assets/video-thumbnails/PThumbnailHardcore.png";
import pThumbnailNestaPalpeLaTeen from "./assets/video-thumbnails/PThumbnailNestaPalpeLaTeen.png";
import pThumbnailNestaGynecologue from "./assets/video-thumbnails/PThumbnailNestaGynecologue.png";
import pThumbnailPpp from "./assets/video-thumbnails/pppppp-final.png";
import pThumbnailPpppp from "./assets/video-thumbnails/ppppppppppp-final.png";
import pThumbnailPpppppp from "./assets/video-thumbnails/ppppppppppppppppppppp-final.png";

interface PVideo {
	title: string;
	description: string;
	thumbnail: string;
	length: number; // in seconds
	views: number;
}

const videos: PVideo[] = [
	{
		title: "Monsieur P - Hélicoptère Israélien avec Tina Kandelaki",
		description:
			"Monsieur P rencontre Tina Kandelaki et profite de l'occasion pour lui faire expérimenter l'hélicoptère israélien.",
		thumbnail: pThumbnailIsraeliHelicopter,
		length: 1459,
		views: 14029,
	},
	{
		title: "Monsieur P - La Mauvaise Femme de Ménage",
		description:
			"Monsieur P engage une femme de ménage qui ne fait pas bien son travail. Il décide de lui donner une leçon.",
		thumbnail: pThumbnailBadHousemaid,
		length: 2238,
		views: 4567,
	},
	{
		title: "Monsieur P - Rencontre Hardcore avec Maria Sharapova",
		description: "Monsieur P rencontre Maria Sharapova pour une séance de sport de chambre hardcore.",
		thumbnail: pTHumbnailHardcore,
		length: 2938,
		views: 18956,
	},
	{
		title: "Monsieur P ft. Nesta - Palpe La Teen",
		description: "Monsieur P et Nesta décident que tel le seigneur Palpatine, ils palperont la teen.",
		thumbnail: pThumbnailNestaPalpeLaTeen,
		length: 1238,
		views: 28943,
	},
	{
		title: "Nesta - Le Gynécologue",
		description: "Nesta se fait passer pour un gynécologue et fait des examens très poussés.",
		thumbnail: pThumbnailNestaGynecologue,
		length: 938,
		views: 8943,
	},
	{
		title: "Monsieur Peinture - Apporte la Blanche",
		description: "Monsieur P propose de repeindre la maison de sa cliente, sa peinture à une texture spéciale.",
		thumbnail: pThumbnailPpp,
		length: 5789,
		views: 4303,
	},
	{
		title: "Monsieur P - Leçon de soumission",
		description:
			"Monsieur P donne une leçon de soumission à une jeune femme qui a besoin de se faire remettre à sa place.",
		thumbnail: pThumbnailPpppp,
		length: 4789,
		views: 4303,
	},
	{
		title: "Monsieur P - Opération sponsorisée par les Matelas Emma",
		description: "Monsieur P fait une opération sponsorisée par les Matelas Emma, il teste leur qualité.",
		thumbnail: pThumbnailPpppppp,
		length: 3789,
		views: 4303,
	},
];

export default videos;
export type { PVideo };
