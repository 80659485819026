import { PVideo } from "./Videos";

function PVideoComponent(props: { video: PVideo; onClick: () => void }) {
	return (
		<div
			style={{
				width: "400px",
				height: "350px",
				display: "grid",
				gridTemplateColumns: "1fr",
				placeItems: "center",
				backgroundColor: "#f0f0f005",
				position: "relative",
			}}
			onClick={props.onClick}
		>
			<img
				src={props.video.thumbnail}
				alt=""
				style={{
					width: "320px",
					height: "180px",
					filter: "blur(5px)",
				}}
			/>
			<h3
				style={{
					fontSize: "1rem",
					margin: "0",
					width: "80%",
				}}
			>
				{props.video.title}
			</h3>
			<p
				style={{
					fontSize: "0.75rem",
					margin: "0",
					width: "80%",
				}}
			>
				{props.video.description.substring(0, 100) + (props.video.description.length > 100 ? "..." : "")}
			</p>
			<p
				style={{
					fontSize: "0.75rem",
					margin: "0",
					width: "80%",
					textAlign: "right",
				}}
			>
				{props.video.views} vues
			</p>
			<p
				style={{
					fontSize: "1rem",
					margin: "0",
					width: "fit-content",
					textAlign: "right",
					position: "absolute",
					right: "10%",
					padding: "0.5rem",
					backgroundColor: "#222222",
				}}
			>
				{parseInt((props.video.length / 60).toString())}:{props.video.length % 60}
			</p>
		</div>
	);
}

export default PVideoComponent;
