import React from "react";
import videos from "./Videos";
import Video from "./Video";
import colorSchemes from "./ColorSchemes";
import ReactSearchBox from "react-search-box";
import Logo from "./assets/MonsieurPo.png";
import AccountIcon from "./assets/AccountIcon.png";
import SearchIcon from "./assets/SearchIcon.png";

const styles = {
	App: {
		display: "flex",
		justifyContent: "center",
		height: "fit-content",
		minHeight: "100vh",
		fontSize: "2rem",
		backgroundColor: colorSchemes.backgroundColor,
		color: colorSchemes.color,
	},
	Headers: {
		width: "100%",
		height: /Mobi/.test(navigator.userAgent) ? "15vh" : "10vh",
		display: "flex",
		alignItems: "center",
		backgroundColor: colorSchemes.softerBackgroundColor,
		position: "fixed" as any,
		zIndex: 100,
	},
	Logo: {
		height: "80%",
		margin: "0 1rem",
	},
	SearchBar: {
		height: "100%",
		paddingTop: /Mobi/.test(navigator.userAgent) ? "7.5vh" : "5vh",
		transform: "translateY(-25%)", // Les maths tkt ça marche
		width: "100%",
	},
	AccountIcon: {
		height: "45%",
		margin: "0 1rem",
	},
};

function App() {
	const [showAccountCreation, setShowAccountCreation] = React.useState(false);

	return (
		<div style={styles.App}>
			<header style={styles.Headers}>
				<img style={styles.Logo} src={Logo} alt="" />
				<div style={styles.SearchBar}>
					<style>
						{`
						.react-search-box-dropdown-list-item {
						background-color: ${colorSchemes.softerBackgroundColor} !important;
						color: ${colorSchemes.color} !important;
						}
						`}
					</style>
					<ReactSearchBox
						placeholder="Recherche"
						data={videos.map((video) => ({
							key: video.title,
							value: video.title,
						}))}
						onSelect={(record) => console.log("a", record)}
						onChange={(value) => console.log("b", value)}
						inputBackgroundColor={colorSchemes.softerBackgroundColor}
						inputBorderColor={colorSchemes.color}
						inputFontColor={colorSchemes.color}
						inputFontSize="1rem"
						dropdownBorderColor={colorSchemes.color}
						dropdownHoverColor="#333"
						inputHeight="100%"
						leftIcon={<img src={SearchIcon} alt="" style={{ height: "30%" }} />}
					/>
				</div>
				<img style={styles.AccountIcon} src={AccountIcon} alt="" onClick={() => setShowAccountCreation(true)} />
			</header>
			{showAccountCreation ? (
				<div
					style={{
						width: "100%",
						paddingTop: /Mobi/.test(navigator.userAgent) ? "17.5vh" : "12.5vh",
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "center",
						gap: "1rem",
					}}
				>
					<div
						style={{
							width: "70%",
							height: "30%",
							backgroundColor: "#333333",
							position: "absolute",
							top: "35%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							display: "grid",
							placeItems: "center",
							textAlign: "center",
							fontSize: "1.25rem",
						}}
					>
						Pour accéder à ce contenu, veuillez créer un compte.
						<br />
						Prix de l'abonnement :<br /> 2 499,99€ / an
					</div>
					<div
						style={{
							width: "50%",
							height: "8%",
							backgroundColor: "green",
							position: "absolute",
							top: "60%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							display: "grid",
							placeItems: "center",
						}}
						onClick={() => (window.location.href = "https://www.fbi.gov/investigate")}
					>
						CONTINUER
					</div>
					<div
						style={{
							width: "50%",
							height: "8%",
							backgroundColor: "red",
							position: "absolute",
							top: "70%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							display: "grid",
							placeItems: "center",
						}}
						onClick={() => setShowAccountCreation(false)}
					>
						ANNULER
					</div>
				</div>
			) : (
				<div
					style={{
						width: "100%",
						paddingTop: /Mobi/.test(navigator.userAgent) ? "17.5vh" : "12.5vh",
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
						justifyContent: "center",
						gap: "1rem",
					}}
				>
					{videos.map((video) => (
						<Video video={video} onClick={() => setShowAccountCreation(true)} />
					))}
				</div>
			)}
		</div>
	);
}

export default App;
